import React from "react";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from "react-icons/fa";
import "../Styling/Socials.css"

const Socials= () => {

    return (
        
        <div className="social-media-icons">
            <div className="intro"> Click on any of our socials to view the full Video</div>
            <ul>
                <li> 
                    <p>Youtube</p>
                    <a href="https://youtu.be/l-W3GYOnVAY"target="_blank"><FaYoutubeSquare /> </a>
                </li>
                <li>
                    <p>Facebook</p>
                    <a href="https://www.facebook.com/reel/223384063971321"target="_blank"><FaFacebookSquare /></a>
                </li>
                <li>
                    <p>Instagram</p>
                    <a href="https://www.instagram.com/p/Ct5xCM7A-sN/" target="_blank"><FaInstagramSquare />  </a>
                </li>
            </ul>
        </div>
        

    )
}

export default Socials;