import React from "react";
import "../Styling/AboutUs.css";
import Director3 from "../Assets/director3.jpg";
import Oriana4 from "../Assets/oriana4.jpg";
import Group2 from "../Assets/group2.jpeg";

const About = () => {
    return (
        <div className="about-overall-container">
            <div className="about-container">
                <h2>Talofa lava, Welcome to Oriana Siva Academy!</h2>
                <p>
                    <i>
                        <b>The Oriana Siva (dance) Academy </b>
                    </i>
                    was founded in March 2020.
                </p>
                <p>
                    We are a non-profit organisation that teaches Samoan dance and songs,
                    including Samoan fa’afiafiaga (dance) and traditional Samoan songs.
                </p>
                <p>
                    <b>
                        <i>Oriana Siva Academy </i>
                    </b>
                    was founded as resources are scarce for Samoan children of the diaspora
                    to learn about the Samoan culture. One of the ways that we address
                    this is through <i>siva</i> (dance).
                </p>
                <p>
                    Our goals at 
                    <b>
                        <i> Oriana Siva Academy </i>
                    </b>
                    are threefold:
                    <br/>
                    <b>1.
                    </b>
                    To instil a profound appreciation for Samoan culture and cultivate cultural pride in our students, while fostering technical excellence, elegance, and strength through complete dance education.
                    <br/>
                    <b>2.
                    </b>
                    We prioritise the holistic growth and well-being of our students, promoting self-confidence, discipline, teamwork, and respect.
                    <br />
                    <b>3.</b>
                    We foster an atmosphere of warmth, encouragement, and cultural immersion, we empower individuals to unlock their full potential as dancers and ambassadors of Samoan heritage.
                    </p>
                    <p>
                    As a cornerstone of our community, we actively engage with local churches, organisations, and events, sharing the beauty and significance of Samoan dance. Through performances, workshops, and outreach programs, we raise awareness, celebrate diversity, and build bridges between cultures.
                    </p>
    
             
                <img className="oriana-picture" src={Director3} alt="/" />
                <p>
                    <b>
                        <i>Oriana Siva Academy </i>
                    </b>
                    is a safe space for all ages and abilities to learn and explore one's
                    Samoan heritage.
                </p>
                <p>
                    <b>
                        <i>Our class offerings:</i>
                    </b>
                    <br /> Class 1 – Fatu nini’i - Ages 5-11 $160.00AUD 12 weeks
                    <br /> Class 2 – Fua-matala - Ages 12-17 $200.00AUD 14 weeks
                    <br /> Class 3 – Oriana - Ages 18+ $220.00AUD 14 weeks
                </p>
                <img className="oriana-picture" src={Oriana4} alt="/" />
            
                <p>
                    We welcome all to register for our classes, you do not have to be Samoan to register, however we ask that you respect our culture and the values that come with being a part of the academy.
                    <br/>
                    We look forward to{" "}
                    <i>Oriana Siva Academy</i> being a part of your journey into Samoa's
                    culture, heritage, and identity.
                </p>
                <img className="oriana-picture1" src={Group2} alt="" />
            </div>
        </div>
    );
};

export default About;
