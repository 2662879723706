import React from 'react';
import { InstagramEmbed } from 'react-social-media-embed';
import { Carousel } from 'react-responsive-carousel';
import "../Styling/InstaFeed.css"





const InstaFeed = () => {

    return (

<div className='box'>
        <div className='insta-feed'>
                <InstagramEmbed className="embed1" url="https://www.instagram.com/p/Cvqb274SuOn/" />
                <InstagramEmbed className="embed2" url="https://www.instagram.com/p/CwBzRwuJQRP/" />
                <InstagramEmbed className="embed3" url="https://www.instagram.com/p/CvDsu4-yGEB/" />
                
        </div>

        <div>
                
        </div>

        </div>
    )

}

export default InstaFeed