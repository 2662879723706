import React from "react";

const Storefront = () => {

    return (

        <div>
           Link to the storefront on Shopify
        </div>


    );
};

export default Storefront
