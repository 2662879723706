import React from "react";
import Director2 from "../Assets/director2.JPG"
import Dominic from "../Assets/dominic.JPG"
import Tulasinui from "../Assets/tulasinui.JPG"
import Reagan from "../Assets/thisguy.png"
import Amaramo from "../Assets/amaramo.JPG"
import Ina from "../Assets/ina.JPG"
import Sana from "../Assets/sana.JPG"
import Noname from "../Assets/noname.png"
import "../Styling/Team.css"

const Team = () => {

    return (

        <div className="team-container">
            {/* <h1>
                Meet The Team!
            </h1> */}

            <div className="profile">
                <img className="director-image" src={Director2} alt=""></img>
                <h2>Faleulu Ane </h2>
                <h3>Director </h3>
                <h3>Falelima, Fai'a'ai, Sataua, Satalo - Falealili, Tiavea - Aleipata, Saleimoa and Malie.</h3>
                <p>Faleulu Ane, is the Director of Oriana Siva Academy in Melbourne, Australia.
                    Raised in West Auckland NZ by her beloved late parents Taituave and Toreka, Faleulu’s love for pese and siva Samoa began at Ponsonby Metotisi through her involvement in Sunday School, Aufaipese, Tupulaga and Autalavou.
                    A renowned student and teacher of the prestigious Avondale College in Auckland New Zealand. Faleulū has claimed victory for more than 9 times at the largest Pacific festival stage- the ASB Polyfest, stretching over 3 decades as a student and teacher.
                    Fast track to today Faleulū, her HARDWORKING MAN Luamanuvae Mose Tauiliili and three amazing children Justine, Richmond and MJ now reside in Melbourne. However, her family experienced a tragic loss in 2016 when her daughter Oriana-Lisa Ane-Tauiliili was called into the arms of our Heavenly Father.
                    Keeping Oriana close to her heart, she established Oriana Siva Academy (OSA) in 2020 to honour her daughter's short lived life and create her legacy. 
                </p>
                
            </div>

            <div className="profile">
                <img className="director-image" src={Tulasinui} alt=""></img>
                <h2>Tulasunu'i Fa'amausili</h2>
                <h3>aka "STU" Male Tutor</h3>
                <h3>Malie & Vailoa Palauli</h3>
                <p>My passion and love for Siva samoa started at a young age.
                    <br/>
                    Growing up in a Samoan Methodist church and being a part of Sunday school and Autalavou (Youth), you are introduced to Siva and Pese Samoa at a young age.
                    <br/>
                    As I got older I became a part of and danced for <b>The Sons Of Samoa</b> that would go on to perform interstate and all around Melbourne.
                    <br/>
                    It was here that my passion grew and deepened in the Siva Samoa.
                    <br/>
                    Being a part of the Oriana Siva Academy has given me the opportunity and platform to not only teach Siva samoa but also give back to the community and help our younger community find their identity through music and dance.
                </p>
            </div>

            {/* <div className="profile">
                <img className="director-image" src={Reagan} alt-text=""></img>
                <h2>Lepa Reagan Poutasi Umi</h2>
                <h3>Nose picker </h3>
                <h3>Faletagaloa Safune & Malie</h3>
                <p>I love my cousin Leisa so much, she is so awesome, and talented and without her I would be nothing.
                    <br></br>
                    I like to pick my nose and eat it, and I also like to say things like "Yeeeaaahhhh" and "Nahhhhh BRUVA".
                    I like to snore really loud and wake up the whole house and I like to give my cousin Leisa all my clothes and now
                    that I have her AUS bank account I'm going to send her all my money.
                </p>
           
            </div> */}

            <div className="profile">
                <img className="director-image" src={Dominic} alt=""></img>
                <h2>Dominic Tunupopo</h2>
                <h3>Tutor</h3>
                <h3>Afega, Salelesi and Saoluafata</h3>
                <p>Faatalofa atu i le suafa mamalu o Iesu Keriso! I'm Dominic Tunupopo Taeleipu. I was born in Sydney, and raised in Moata'a and Afega, Samoa.
                    <br/>
                    My parents hail from the villages Afega, Salelesi and Saoluafata.
                    <br/>
                    Oriana Siva Academy has reignited my love for culture and also how it complements my Faith.
                    <br/>
                    I'm humbled to be part of OSA yet alone as a tutor, allowing me to share my love and passion for Christ and culture, to the OSA members.
                    <br/>
                    Ia saga viia le Atua i mea ua na Ia faia!</p>
            </div>

            <div className="profile">
                <img className="director-image" src={Amaramo} alt=""></img>
                <h2>Amaramo Amosa</h2>
                <h3>Tutor</h3>
                <h3>Sa'anapu, Tufutafoe, Vailoa Palauli, Sapapali'i & Fogapoa</h3>
                <p>Talofa my name is Amaramo Talaifono Mayson Amosa also known as Ramo.
                    I was born & raised in Melbourne, Australia.
                    I come from the Village’s of Sa’agapu, Tufutafoe, Vailoa Palauli, Sa’aapapalii & Fogapoa.
                    I found my passion for Siva Samoa through Church & Family at a very young age.
                    I started as a tutor last year in Term 1 but have been with Oriana Siva Academy since The Launch in 2019.
                    I hope to inspire people of all ages to grow their Siva Samoa especially our Younger Generation.
                    Fa'afetai lava
                </p>
            </div>

            <div className="profile">
                <img className="director-image" src={Ina} alt=""></img>
                <h2>Faafofogainatatalo Melissa Amosa</h2>
                <h3>aka "Ina" Tutor</h3>
                <h3>Sa'anapu, Tufutafoe, Fogapoa, Sapapali'i and Vailoa Palauli </h3>
                <p>I was born and raised in Melbourne Australia.
                    I hail from the villages of Sa'anapu, Tufutafoe, Fogapoa, Sapapalii and Vailoa Palauli.
                    I enjoy spending time with my family and friends, attending church, performing outdoor activities and helping people where I can.
                    I am extremely excited to begin this new journey I am taking on as an Oriana Tutor for Term 2 - 2023, to share my passion and love for siva and pese Samoa alongside my sisters and brothers

                    Faafetai Lava

                </p>
            </div>
            
            <div className="profile">
                <img className="director-image" src={Sana} alt=""></img>
                <h2>Susana Niupulusu</h2>
                <h3>Tutor</h3>
                <h3>Tiavea, Aleipata & Vaito’omuli, Palauli</h3>
                <p>My name is Susana Roseabella Niupulusu, from the villages of Tiavea, Aleipata & Vaito’omuli, Palauli.

                    A little bit about me;
                    I am the eldest child of Pastor Musika Niupulusu & late Ioana Niupulusu.
                    I was Born in Christchurch, New Zealand, raised in Brisbane, Queensland and now currently residing in Melbourne, Victoria.
                    Am a former member of Nesian Pearl Dance Group and a very proud Christian.
                    I am honoured and excited for this next term, as I will be one of the new Tutors of Oriana Siva Academy and cannot wait to share my love for our Samoan culture through siva, pese & more with all our students, but also learning from you all as well.
                    See you all soon & God Bless!
                </p>
            </div>
            <div className="profile">
                <img className="director-image" src={Noname} alt=""></img>
                <h2>Could this be you??</h2>
                <h3>Future OSA superstar! </h3>
                <h3>Do you have a passion for the arts? Do you see yourself volunteering as part of the team? We value all skillsets at Oriana Siva Academy!</h3>
                <p>Head to our contact page, send us a message!</p>

            </div>
            <div className="profile">
                <img className="director-image" src={Noname} alt=""></img>
                <h2>Could this be you??</h2>
                <h3>Future OSA superstar! </h3>
                <h3>Do you have a passion for the arts? Do you see yourself volunteering as part of the team? We value all skillsets at Oriana Siva Academy!</h3>
                <p>Head to our contact page, send us a message!</p>

            </div>
        </div>
    );
};

export default Team
