import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import { Link } from 'react-router-dom';
import Socials from "./Socials";
import "../Styling/Footer.css"
import OrianaLogo from "../Assets/OrianaLogo2.png";
import { FaYoutubeSquare } from "react-icons/fa";
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from "react-icons/fa";
import OrianaTCs from "../Assets/Oriana_Terms_and_Conditions_2023.pdf"
import OrianaPrivacy from "../Assets/OSA_Privacy_Policy_2023.pdf"

const Footer = () => {
const currentYear = new Date().getFullYear();

    return (

        <div className="footer-container">
            <div className="logo">
            <img src={OrianaLogo} alt-text="oriana-logo"></img>

            </div>
            <div className="links">
                <p>QUICK LINKS:</p>
                <ul>
                    <li>
                        <Link to='/about'> ABOUT ORIANA</Link>
                    </li>
                    <li>
                        <Link to="/team">THE TEAM</Link>
                    </li>
                    <li>
                        <Link to="/contact">CONTACT</Link>
                    </li>
                    <li>
                        <Link to="/blog">BLOG</Link>
                    </li>
                    <li>
                        <a href="https://orianasivaacademy.com/" target="_blank" rel="noopener noreferrer" >STOREFRONT</a>
                    </li>
                    </ul>
                
            </div>
          
            <div className="terms">
                TERMS AND PRIVACY:
                <p>
                    <a href={OrianaTCs} download="../Assets/Oriana_Terms_and_Conditions_2023.pdf">DOWNLOAD TCs</a>

                </p>
                <p>
                    <a href={OrianaPrivacy} download="../Assets/OSA_Privacy_Policy_2023.pdf">DOWNLOAD Privacy</a>

                </p>
            </div>

         
             
        
            <div className="socials-links">
                FOLLOW ORIANA SIVA ACADEMY:
                <ul>
                <a href="https://www.youtube.com/@OrianaSivaAcademy" target="_blank"><FaYoutubeSquare /> YouTube </a>
                <a href="https://www.facebook.com/orianasivaacademy/" target="_blank"><FaFacebookSquare /> Facebook </a>
                    <a href="https://www.instagram.com/orianasivaacademy/" target="_blank"><FaInstagramSquare /> Instagram </a>
                </ul>
        </div>

            <div className="header">
                <p> Copyright &copy; {currentYear} Oriana Siva Academy.
                    <br></br>All rights reserved.
                    <br></br>This website is an <b>LSK</b> creation.
                </p>
            </div>
        </div>  

    );
};

//how do I code it so the year is automatically generated on loading for copyright?

export default Footer