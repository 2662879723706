import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import Swiper from 'swiper';
// import { Swiper, SwiperSlide } from "swiper/react"
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home'
import About from './Pages/About'
import Team from './Pages/Team'
import Contact from './Pages/Contact'
import Blog from './Pages/Blog'
import Registration from './Pages/Registration'
import Storefront from './Pages/Storefront'
import ScrollButton from './Components/ScrollButton';


function App() {
  return (
    <Router>
    <div>
        <Header/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
          <Route path="/about" element={<About/>} />
          <Route path="/team" element={<Team/>} />
          <Route path="/contact" element={<Contact/>} />
          <Route path="/blog" element={<Blog/>} />
          <Route path="/registration" element={<Registration/>} />
          <Route path="/storefront" element={<Storefront />} />
        </Routes>
        <ScrollButton/>
       <Footer/>
      </div>
    </Router>
  );
}

export default App;
