import React from "react";
import "../Styling/Registration.css"

const Registration = () => {

    return (

        <div className="registration-container">
            <h2>Registrations have closed for term 2 </h2>
            <p>Please fill in the form below, or click/tap <a href="https://forms.gle/yGXxK8FKGjs39hgb6" target="_blank">HERE.</a></p>
            <div className="google-form">
            {/* <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSdfLbWhoqOyYa4OZrqXf2zRlP-YyZ3ozmdO_HWKBJqCc6Kzig/viewform?embedded=true" width="700" height="3233" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe> */}
            </div>
            {/* <h2>PLEASE SCROLL BACK UP </h2> */}
            </div>
    );
};

export default Registration
