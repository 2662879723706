import React, { useState, useRef } from "react";


import Term2 from "../Assets/term2launch.jpg";
import VideoSource from '../Assets/OSACLIP.mp4';
import WeAreSamoa from '../Assets/wearesamoa.mp4';
import SamoaNeiGalo from '../Assets/samoaneigalo.mp4';
import Socials from "../Components/Socials.js";
import SocialsHome from "../Components/SocialsHome";

import Group1 from "../Assets/group1.jpg";
import Group3 from "../Assets/group3.jpg";
import Group4 from "../Assets/group4.jpg";
import Posters from "../Components/Posters";

import '../Styling/Home.css';
import InstaFeed from "../Components/InstaFeed";

const Home = () => {
    const videoRef = useRef(null);
    const [videoEnded, setVideoEnded] = useState(false);

    const handleVideoEnded = () => {
        videoRef.current.currentTime = 2;
        setVideoEnded(true);
    };

    return (
        <div className="homepage-container">
            <div className="welcome">
                <div className="opening">
                    <h1>WE ARE ORIANA SIVA ACADEMY</h1>
                    <p>
                        At Oriana Siva Academy (OSA), our mission is to preserve and promote the rich heritage, culture, and traditions of Samoa through the art of dance (siva).
                        <br />
                        We provide a nurturing and inclusive environment for individuals of all ages and backgrounds to learn and engage in the vibrant world of Samoan siva.
                        <br />
                        Join us in dancing with pride, respect, and joy as we preserve the past and inspire the future.
                    </p>
                </div>
                <br />
                <br/>
            </div>

            <div className="updates">
                <h2><u>LATEST NEWS</u></h2>
        
                <InstaFeed />
            </div>

            <div className="video-source">
                <br/>
                <h2><u>MAY 2023 ORIANA SIVA ACADEMY GOES TO HAWAI'I</u></h2>
                <h3>Press play:</h3>
                <video ref={videoRef} src={VideoSource} controls controlsList="nodownload" onEnded={handleVideoEnded} />
                <h3> All video's courtesy of <a href="https://www.instagram.com/elmst__/" target="_blank" rel="noopener noreferrer">Elm St Films</a> All photography <a href="https://www.instagram.com/spxcehexd/" target="_blank" rel="noopener noreferrer">Spxehexd</a></h3>
                <Socials />
            </div>

            <div className="shows-container">
                <br />
                <br/>
                <h2><u>PREVIOUS ORIANA SIVA ACADEMY SHOWS</u></h2>
                <br/>
                <div className="posters">
                    {Posters.map((poster, index) => (
                        <img key={index} src={poster} alt={`poster${index + 1}`} />
                    ))}
                </div>
            </div>

            <div className="showcase-container">
                <div className="video-source">
                    <h2><u>APRIL 2023 HIGHLIGHTS</u></h2>
                    <video src={WeAreSamoa} controls controlsList="nodownload"  />
                </div>
            </div>

            <div className="showcase-container">
                <div className="video-source">
                    <h2><u>NOVEMBER 2022 HIGHLIGHTS</u></h2>
                    <video src={SamoaNeiGalo} controls controlsList="nodownload" />
                </div>
            </div>
               <br/>
            
            <div className="image-gallery">
                <img src={Group3} alt="tutors tutoring" />
                <img src={Group1} alt="tutors tutoring" />
                <img src={Group4} alt="tutors tutoring" />
            </div>
            <SocialsHome />
        </div>
    );
};

export default Home;
