import React from "react";
import "../Styling/Contact.css"
import { FaFacebookSquare } from 'react-icons/fa';
import { FaInstagramSquare } from "react-icons/fa";
const Contact = () => {

    return (

        <div className="overall-container">
            <div className="social-media-icons">
                <div className="intro"> </div>
                <ul>
                    <li>
                        <p>Facebook</p>
                        <a href="https://www.facebook.com/orianasivaacademy/" target="_blank"><FaFacebookSquare /></a>
                    </li>
                    <li>
                        <p>Instagram</p>
                        <a href="https://www.instagram.com/orianasivaacademy/" target="_blank"><FaInstagramSquare />  </a>
                    </li>
                </ul>
            </div>
            <div className="contact-container">
                <h4> 
                    <br />
                    If you are experiencing trouble seeing or filling out the form below, please click/tap <a href="https://forms.gle/V7w6Sgb1CGVLXVbF7" target="_blank">HERE.</a>
                    <br />
                    <br/>
                    Our social's are linked above and we are contactable via messenger or instagram message.
                    <br />
                    We welcome all feedback and complaints so please leave your email address and contact number so we can respond.
                    <br />
                    <br />
                    <i>Any bulling or harassment messages will not responded to, and will be deleted.</i>
                    <br />
                    <br/>
                    We endevour to respond to all messages within 72 hours, however there may be a delay in responding as being a not-for-profit we still have day jobs :)
                    <br/>
                    Fa'afetai!
                </h4>
            </div>
            <div className="google-contact">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScXH0QDWe7zVy5lgktRzMG97WxwNpwt5mIW05fqT2hTtK0Yow/viewform?embedded=true" width="700" height="700" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
            
                </div>
        </div>


    );
};

export default Contact
