import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import { FaTimes } from "react-icons/fa";
import '../Styling/Header.css'
import Logo from '../Assets/OrianaLogo2.png'
import Dancer1 from '../Assets/dancer1bg.png'
import Dancer2 from '../Assets/dancer2bg.png'
import Dancer3 from '../Assets/dancer3bg.png'
import Dancer4 from '../Assets/dancer4bg.png'
import Dancer5 from '../Assets/dancer5bg.png'
import Dancer6 from '../Assets/dancer6bg.png'
import Dancer7 from '../Assets/dancer7bg.png'
import Dancer8 from '../Assets/dancer8bg.png'
import Dancer9 from '../Assets/dancer9bg.png'

const Header = () => {

    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    
    const handleOnClick = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen)
    }

    const closeOnClick = () => {
        setIsMobileMenuOpen(false);
    };

    return (
        <header>
        
            <div className="logo">
                <Link to="/">
                    <img src={Logo} alt="oriana siva logo" />
                </Link>
            </div>
            <div className="logo-mobile">
                <Link to="/">
                    <img src={Logo} alt="oriana siva logo" />
                </Link>
            </div>
          
            {/* dancers animation test */}
            <div className="dancers-container">
                <div className="dancer">
                    <img src={Dancer8} alt="Dancer 1" />
                </div>
                <div className="dancer">
                    <img src={Dancer2} alt="Dancer 2" />
                </div>
                <div className="dancer">
                    <img src={Dancer4} alt="Dancer 3" />
                </div>
                <div className="dancer">
                    <img src={Dancer6} alt="Dancer 4" />
                </div>
                <div className="dancer">
                    <img src={Dancer1} alt="Dancer 5" />
                </div>
                <div className="dancer">
                    <img src={Dancer7} alt="Dancer 6" />
                </div>
                <div className="dancer">
                    <img src={Dancer5} alt="Dancer 7" />
                </div>
                <div className="dancer">
                    <img src={Dancer3} alt="Dancer 8" />
                </div>
                <div className="dancer">
                    <img src={Dancer9} alt="Dancer 9" />
                </div>
                {/* Add more .dancer elements for additional dancers */}
            </div>
            
            
            <nav>     {isMobileMenuOpen ? <FaTimes className='icons' onClick={handleOnClick} /> : <FaBars className='icons' onClick={handleOnClick} />}
                <ul className='navbar' >
                    <li>
                        <NavLink to='/' >HOME</NavLink>
                    </li>
                    <li>
                        <NavLink to='/about'> ABOUT ORIANA</NavLink>
                    </li>
                    <li>
                        <NavLink to="/team">THE TEAM</NavLink>
                    </li>
                    <li>
                        <NavLink to="/contact">CONTACT</NavLink>
                    </li>
                    <li>
                        <NavLink to="/blog">BLOG</NavLink>
                    </li>
                </ul>
            </nav>
          
            
            
           
            {
                isMobileMenuOpen ? <div className='mobile-navbar'>
                                     
                    {
                        
                    <nav className='navbar-mobile'>
                        <ul>
                            <li>
                                    <NavLink onClick={closeOnClick} to='/'>HOME</NavLink>
                            </li>
                            <li>
                                    <NavLink onClick={closeOnClick} to='/about'> ABOUT ORIANA</NavLink>
                            </li>
                            <li>
                                    <NavLink onClick={closeOnClick} to="/team">THE TEAM</NavLink>
                            </li>
                            <li>
                                    <NavLink onClick={closeOnClick} to="/contact">CONTACT</NavLink>
                            </li>
                            <li>
                                    <NavLink onClick={closeOnClick} to="/blog">BLOG</NavLink>
                            </li>
                            <li>
                                    <NavLink onClick={closeOnClick} to="/registration">REGISTER NOW</NavLink>
                            </li>
                            <li>
                                    <a href="https://orianasivaacademy.com/" target="_blank" rel="noopener noreferrer" onClick={closeOnClick} >STOREFRONT</a>
                            </li>
                        </ul>
                        </nav>}
                
                </div> : ''
          }

            <div className="announcement-banner">
                <p>Announcement: If any changes are to occur during the term, All students will be notified by our admin team 🔔</p>
            </div>

            <div className='buttons'>
            <div className="storefront-button">
                <a href="https://orianasivaacademy.com/" target="_blank" rel="noopener noreferrer" >STOREFRONT</a>
            </div>
            <div className="registration-button">
                <NavLink to="/registration">REGISTER NOW</NavLink>
            </div>
            </div>
         


            
        </header>
    );
};

export default Header;