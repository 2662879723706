// posters.js

import Poster1 from "../Assets/poster1.png";
import Poster2 from "../Assets/poster2.png";
import Poster3 from "../Assets/poster3.png";
import Poster5 from "../Assets/poster5.png";
import Poster6 from "../Assets/poster6.png";
import Poster7 from "../Assets/poster7.jpg";

const Posters = [
    Poster6,
    Poster1,
    Poster2,
    Poster7,
    Poster3,
    
    Poster5,
   
    
];

export default Posters;
