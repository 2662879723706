import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import SocialsHome from "../Components/SocialsHome";



import "../Styling/Blog.css" 
import SamoaVideo from "../Assets/samoaneigalo.mp4"
import WeAreSamoa from "../Assets/wearesamoa.mp4"
import Osa2022 from "../Assets/osa2022.mp4"
import OsaApril2022 from "../Assets/osaapril2022.mp4"
import Nov1 from "../Assets/november1.jpg"
import Nov2 from "../Assets/november2.jpg"
import Nov3 from "../Assets/november3.jpg"
import Nov4 from "../Assets/november4.jpg"
import NovK1 from "../Assets/novemberkids1.jpg"
import NovK2 from "../Assets/novemberkids2.jpg"
import NovK3 from "../Assets/novemberkids3.jpg"
import NovK5 from "../Assets/novemberkids5.jpg"
import NovK6 from "../Assets/novemberkids6.jpg"
import Tut1 from "../Assets/tutor1.jpg"
import Tut2 from "../Assets/tutor2.jpg"
import Tut3 from "../Assets/tutor3.jpg"
import Tut4 from "../Assets/tutor4.jpg"
import Tut5 from "../Assets/tutor5.jpg"
import Tut6 from "../Assets/tutor6.jpg"
import Tut7 from "../Assets/tutor7.jpg"
import Tut8 from "../Assets/tutor8.jpg"
import Tut9 from "../Assets/tutor9.jpg"
import Oriana25 from "../Assets/oriana25.jpg"
import Oriana26 from "../Assets/oriana26.jpg"
import Oriana1 from "../Assets/oriana1.jpg"
import Oriana5 from "../Assets/oriana5.jpg"
import Oriana11 from "../Assets/oriana11.jpg"
import Oriana7 from "../Assets/oriana7.jpg"
import Oriana9 from "../Assets/oriana9.jpg"
import Oriana17 from "../Assets/oriana17.jpg"
import Oriana18 from "../Assets/oriana18.jpg"
import Oriana21 from "../Assets/oriana21.jpg"
import Oriana19 from "../Assets/oriana19.jpg"
import Oriana8 from "../Assets/oriana8.jpg"
import Oriana13 from "../Assets/oriana13.jpg"
import Oriana23 from "../Assets/oriana23.jpg"
import Oriana12 from "../Assets/oriana12.jpg"
import Oriana20 from "../Assets/oriana20.jpg"




const Blog = () => {

    return (

        <div className="blog-container">
            <div className="title">
                <h2>Talofa again! We're Oriana Siva Academy, but you can call us OSA for short.</h2>
                <h3>We've had some adventures, and this page is where we will tell you about them!</h3>
                <h3>Videography <a href="https://www.instagram.com/elmst__/" target="_blank" rel="noopener noreferrer">Elm St Films</a></h3>
                <h3>Photography <a href="https://www.instagram.com/spxcehexd/" target="_blank" rel="noopener noreferrer">Spxehexd</a></h3>
            </div>
            <div className="firstgroup">
                <h4>
                    <b><u>APRIL 2022 - OSA PERFORMS AT HALFTIME SHOW AAMMI PARK</u></b>
                </h4>
                
                <div className="video-november">
                    <p>Press play to see highlights:</p>
                    <video src={OsaApril2022} controls controlsList="nodownload">
                    </video>
                </div>
                {/* <Carousel infiniteLoop autoPlay>
                //to add in once we have pictures
                    <div className="november">
                        <img src={Oriana25} alt-text="/"></img>
                    </div>
                </Carousel> */}

            </div>

            <div className="firstgroup">
                <h4>
                    <b><u>JUNE 2022 -  "CELEBRAING SAMOA'S 60th INDEPENDENCE"</u></b>
                </h4>
                <div className="video-november">
                    <p>Press play to see highlights:</p>
                    <video src={Osa2022} controls controlsList="nodownload">
                    </video>
                </div>
                {/* <Carousel infiniteLoop autoPlay>
                //to add in once we have pictures
                    <div className="november">
                        <img src={Nov1} alt-text="/"></img>
                    </div>
                </Carousel> */}

            </div>


                      <div className="firstgroup">
                <h4>
                    <b><u>NOVEMBER 2022 -  "SAMOA NE’I GALO, E MAOTA TAUAVE LE ATUNU'U"</u></b>
                </h4>
                <p>
                    <b>In November 2022</b>, our term 2 production "Samoa ne’i galo, e maota tauave le Atunu’u" was the first production of its kind in Melbourne run by a homegrown siva academy. 
                    <br/>
                    It was a success with the class performing to a sold-out theatre.
                    <br/>
                    What underpinned our show was the Samoan values of Faith, Family, and Culture.
                </p> 
                <div className="video-november">
                    <p>Press play to see highlights:</p>
                    <video src={SamoaVideo} controls controlsList="nodownload">
                    </video>
                </div>
                <Carousel infiniteLoop autoPlay>
                <div className="november">
                <img src={Nov1} alt="/"></img>
                </div>
                <div className="november">
                <img src={Nov2} alt="/"></img>
                </div>
                <div className="november">
                <img src={Nov3} alt="/"></img>
                </div>
                <div className="november">
                <img src={Nov4} alt="/"></img>
                </div>
                <div className="november">
                <img src={NovK1} alt="/"></img>
                </div>
                <div className="november">
                <img src={NovK2} alt="/"></img>
                </div>
                <div className="november">
                <img src={NovK3} alt="/"></img>
                </div>
                <div className="november">
                <img src={NovK5} alt="/"></img>
                </div>
                <div className="november">
                <img src={NovK6} alt-text="/"></img>
                    </div>
                    <div className="november">
                        <img src={Oriana26} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Oriana25} alt="/"></img>
                    </div>
                </Carousel>
              
            </div>

            <div className="may">
                <h4> <b><u>APRIL 2023 - "WE ARE SAMOA"</u></b>
                </h4>
                <p>
                    <b>In April 2023</b>, we showcased Oriana Siva Academy with “We are Samoa",
                    <br/>The show was a huge success, growing our student base.
                </p>
                <div className="video-november">
                    <p>Press play to see highlights:</p>
                    <video src={WeAreSamoa} controls controlsList="nodownload">
                    </video>
                </div>
                <Carousel infiniteLoop autoPlay>
                    <div className="may">
                        <img src={Oriana1} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana5} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana11} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana7} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana9} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana17} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana18} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana21} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana19} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana8} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana13} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana23} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana12} alt="/"></img>
                    </div>
                    <div className="may">
                        <img src={Oriana20} alt="/"></img>
                    </div>
                </Carousel>
                
            </div>
           
            <div className="tutor-group">
                <h4><b><u>A SHOUT OUT TO OUR AMAZING TUTORS</u></b></h4>
                <p>
                    A lof of the work we do wouldn't be possible without the countless hours spent by our tutors
                    <br/>
                    doing what they are passionate about for <b><i>Oriana Siva Academy.</i></b>
                    <br/>
                    Peep below some background shots of the the behind the scenes work that goes into making our shows and productions successful!
                    <br/>
                    <br/>
                </p>
               
                <Carousel infiniteLoop autoPlay>
                    <div className="november">
                        <img src={Tut1} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut2} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut3} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut4} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut5} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut6} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut7} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut8} alt="/"></img>
                    </div>
                    <div className="november">
                        <img src={Tut9} alt="/"></img>
                    </div>
                    
                </Carousel>
            </div>
            {/* <SocialsHome /> */}
        </div>


    );
};

export default Blog
